import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import Card from 'reactstrap/lib/Card';

import {
  BuildPriceCreativeConfigDefaultProps,
  BuildPriceCreativeConfigPropTypes,
} from 'client/data/models/native-ads-creative-config';
import {
  SiteServedAdDefaultProps,
  SiteServedAdPropTypes,
} from 'site-modules/shared/components/ad-unit/ad-unit-prop-types';
import { BUILD_PRICE_AD } from 'site-modules/shared/components/native-ad/utils/constants';
import { withSiteServedAdWrapper } from 'site-modules/shared/components/native-ad/site-served-ad-wrapper/site-served-ad-wrapper';
import { definePlatform } from 'site-modules/shared/components/native-ad/utils/utils';
import { siteServedAdTrackingWrapper } from 'site-modules/shared/components/native-ad/site-served-ad-tracking-wrapper/site-served-ad-tracking-wrapper';
import { svgCarTrims, svgColorPalette, svgPriceTag } from 'client/images/inline-svgs/ads/buildprice-ad-icons';
import { getPlural } from 'client/utils/plural';

import { AdLabel } from 'site-modules/shared/components/native-ad/native-ad-components/ad-label/ad-label';
import { ConquestRibbon } from 'site-modules/shared/components/native-ad/native-ad-components/conquest-ribbon/conquest-ribbon';
import { CtaButtonText } from 'site-modules/shared/components/native-ad/native-ad-components/cta-button-text/cta-button-text';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { DfpTracking } from 'site-modules/shared/components/native-ad/utils/dfp-tracking';

const Styles = {
  oemLogo: {
    maxHeight: '24px',
  },
  conquestRibbon: {
    height: '20px',
    left: '-16px',
    top: '-4px',
  },
  ul: {
    paddingLeft: 0,
    listStyleType: 'none',
    marginBottom: '4px',
    rowGap: '4px',
    columnGap: '16px',
  },
  li: {
    display: 'grid',
    gridTemplateColumns: '24px auto',
    alignItems: 'center',
  },
  mb_0_75: {
    marginBottom: '12px',
  },
  mb_0_25: {
    marginBottom: '4px',
  },
  mr_1: {
    marginRight: '16px',
  },
};

function AtGlanceItem({ icon, text, className }) {
  return (
    <li style={Styles.li}>
      {icon}
      <span className={classnames('text-gray-darker', className)}>{text}</span>
    </li>
  );
}

AtGlanceItem.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export function BuildPriceButtonAtAGlanceAdUI({
  adRef,
  adClickTrackingListener,
  adLoadTrackingListener,
  adTrackers,
  position,
  creativeConfig,
  mobile,
  wrapperClass,
  ivtClassName,
  componentClassName,
  isVerticalLayout,
  isBlueBtn,
}) {
  const cta = get(creativeConfig, 'creativeConfigData.cta');

  const loadTrackingListener = useCallback(
    event => {
      adLoadTrackingListener(event, cta);
    },
    [adLoadTrackingListener, cta]
  );

  if (!cta) return null;

  const {
    siteServedAdsUpdater,
    warranty,
    creativeConfigData: {
      logo,
      lineItemId,
      linkDisplayUrl,
      siteServedCreativeId,
      target,
      isConquest,
      vehicleInfo,
      trimNames,
      headline,
    },
    traffickingData: { clickTracker },
  } = creativeConfig;

  const ctaURL = clickTracker[definePlatform(mobile)];
  const ctaTarget = target || '_blank';

  const { extColorCnt, pkgCnt } = get(vehicleInfo, 'submodels[0]', {});
  const trimCnt = get(trimNames, 'length');

  const showAtAGlance = !!extColorCnt || !!trimCnt || !!pkgCnt || !!warranty;

  const nativeStyle = BUILD_PRICE_AD.NATIVE_STYLE;

  const className = classnames({ 'size-16': !isConquest, 'size-14': isConquest });

  return (
    <Card
      className={classnames('build-price-button-at-a-glance-ad pos-r', wrapperClass, ivtClassName)}
      data-tracking-parent={nativeStyle}
      data-viewability={`${nativeStyle}-${position}`}
      innerRef={adRef}
    >
      <DfpTracking
        lineItemId={lineItemId}
        siteServedCreativeId={siteServedCreativeId}
        mobile={mobile}
        nativeStyle={nativeStyle}
        position={position}
        slotRenderEndListener={loadTrackingListener}
        siteServedAdsUpdater={siteServedAdsUpdater}
      />
      {adTrackers}
      <a
        className={classnames('site-served-ad-link no-focus text-decoration-none d-block py-0_5', componentClassName)}
        href={ctaURL}
        rel="noopener noreferrer"
        target={ctaTarget}
        onClick={adClickTrackingListener}
      >
        <AdLabel />
        <div style={Styles.mb_0_75}>
          {isConquest ? (
            <ConquestRibbon style={Styles.conquestRibbon} />
          ) : (
            <img alt="" src={logo} style={Styles.oemLogo} />
          )}
        </div>
        <div className={classnames('d-flex', { 'flex-column align-items-start': isVerticalLayout })}>
          {!showAtAGlance && !isConquest && (
            <section className="w-100 pr-0_25">
              <ContentFragment>{headline}</ContentFragment>
            </section>
          )}
          {isVerticalLayout && headline && (
            <div className="font-weight-bold text-capitalize text-gray-darker size-14 mb-0_5">
              <ContentFragment>{headline}</ContentFragment>
            </div>
          )}
          {(showAtAGlance || isConquest) && (
            <section className="w-100">
              {!isVerticalLayout && isConquest && (
                <div className="font-weight-bold text-capitalize text-gray-darker size-14" style={Styles.mb_0_25}>
                  <ContentFragment>{headline}</ContentFragment>
                </div>
              )}
              <ul
                style={Styles.ul}
                className={classnames('text-nowrap d-flex align-items-start', {
                  'flex-column': !isVerticalLayout,
                  'mb-0_5': isVerticalLayout,
                })}
                aria-label="At a glance"
              >
                {!!extColorCnt && (
                  <AtGlanceItem
                    className={className}
                    icon={svgColorPalette}
                    text={`${extColorCnt} ${getPlural('Color', extColorCnt)}`}
                  />
                )}
                {!!trimCnt && (
                  <AtGlanceItem
                    className={className}
                    icon={svgCarTrims}
                    text={`${trimCnt} ${getPlural('Trim', trimCnt)}`}
                  />
                )}
                {!!pkgCnt && (
                  <AtGlanceItem
                    className={className}
                    icon={svgPriceTag}
                    text={`${pkgCnt} ${getPlural('Package', pkgCnt)}`}
                  />
                )}
              </ul>
            </section>
          )}
          <section
            className={classnames('text-center d-flex flex-column', {
              'ml-0_5': !isVerticalLayout && (showAtAGlance || isConquest),
              'mx-auto': !isVerticalLayout && !showAtAGlance && !isConquest,
            })}
          >
            <span
              className={classnames('btn size-16', {
                'btn-outline-blue-50 py-0_75': !isBlueBtn,
                'px-2': !isBlueBtn && !isConquest,
                'px-1': !isBlueBtn && isConquest,
                'btn-blue-50 text-transform-none font-weight-medium py-0_5 px-0_75': isBlueBtn,
              })}
            >
              <CtaButtonText text={cta} />
            </span>
            <span className="xsmall text-blue-40 text-decoration-none my-0_25">{linkDisplayUrl}</span>
          </section>
        </div>
      </a>
    </Card>
  );
}

BuildPriceButtonAtAGlanceAdUI.propTypes = {
  ...SiteServedAdPropTypes,
  creativeConfig: BuildPriceCreativeConfigPropTypes,
  wrapperClass: PropTypes.string,
  componentClassName: PropTypes.string,
  isBlueBtn: PropTypes.bool,
  isVerticalLayout: PropTypes.bool,
};

BuildPriceButtonAtAGlanceAdUI.defaultProps = {
  ...SiteServedAdDefaultProps,
  creativeConfig: BuildPriceCreativeConfigDefaultProps,
  wrapperClass: null,
  componentClassName: 'px-1',
  isBlueBtn: false,
  isVerticalLayout: false,
};

export const BuildPriceButtonAtAGlanceAdUIContainer = siteServedAdTrackingWrapper(BuildPriceButtonAtAGlanceAdUI);

const BuildPriceButtonAtAGlanceAdWrapper = withSiteServedAdWrapper(BuildPriceButtonAtAGlanceAdUIContainer);

export function BuildPriceButtonAtAGlanceAd(props) {
  return <BuildPriceButtonAtAGlanceAdWrapper {...props} adType={BUILD_PRICE_AD} />;
}
